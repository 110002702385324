import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/esm/Container';

function headers() {
  return (
    <Container fluid style={{height:'1rem', backgroundColor:'dark' }}>
        
    </Container>
    
  );
}

export default headers;