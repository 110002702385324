 const Text11 = () => {
    return(
      <p  className="text-xxl-center text-white p-5" >Welcome to Kest BPO, a new start-up organization of cutting-edge backend solutions for the insurance industry from offshore.<br/>
       With a focus on efficiency, innovation, and seamless integration, we empower insurance companies to optimize their operations and deliver unparalleled services to their clients.
    </p>
    );
}


const Text22 = () => {
  return(
    <p  className="text-xxl-center text-white">
    At Kest BPO, we bring a wealth of experience <br/>in developing robust backend solutions specifically <br/>tailored to the dynamic needs of the insurance sector. <br/>
    Our team comprises seasoned professionals with deep<br/> industry knowledge and technical expertise.
    </p>
  )
}

const Text33 = () => {
    return(
      <p className="text-xxl-start p-5">We pride ourselves on maintaining a state-of-the-art technology infrastructure designed to meet the evolving demands of the insurance landscape.
         Our scalable and secure backend systems leverage the latest advancements in data analytics, artificial intelligence, and cloud computing to streamline processes and enhance overall operational efficiency.</p>
    )
  }
  const Text44 = () => {
    return(
      <p className="text-xxl-start p-5">We are committed to providing our clients with backend services that go beyond expectations. 
        Our dedication to excellence is reflected in our unwavering focus on delivering reliable, secure, and innovative solutions that empower insurance agencies and brokerages to thrive in a competitive environment.</p>
    )
  }
  export {Text11, Text22, Text33,Text44};